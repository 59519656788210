(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {
        let fns = {};

        (function fn_range_slider() {
            // can be called like this
            // window.lui["comp_site_products_used"].fns["fn_range_slider"]()

            let range = selector.find(".part_ui_range");
            if (range.length) {
                range.each(function(){
                    let elm = $(this);

                    if(elm.find('.noUi-target').length) {
                        return
                    }

                    $.importScript(cdnjs.autoNumeric, function(){
                        $.importScript(cdnjs.noui, function(){
                            elm.lib_noui({
                                elm_min: elm.find(`[data-range-input="min"]`),
                                elm_max: elm.find(`[data-range-input="max"]`),
                                elm_min_hidden: elm.find(`[data-range-input="min"]`).next(),
                                elm_max_hidden: elm.find(`[data-range-input="max"]`).next(),
                                unit: elm.data("unit")
                            });
                        });
                    });
                });
            }

            fns.fn_range_slider = fn_range_slider;
        })();

        selector.on("click", "[data-toggle-filter]", function(){
            if (win.width() < 960) {
                $(this).closest(".elm_body_filter").toggleClass("state--active");
            }
        });

        selector.on("click", "[data-toggle-submenu]", function(){
            const elm = $(this);
            const item = elm.closest('[data-ref="item"]')
            const submenu = item.find('[data-ref="submenu"]')

            if(elm.hasClass('mod--active')) {
                submenu.stop().slideUp(300, function () {
                    elm.removeClass('mod--active')
                })
            } else {
                elm.addClass('mod--active')
                submenu.stop().slideDown(300)
            }
        });

        var filterRequest = null;
        doc.on('change', '#productFilterForm :input', function (e) {
            e.preventDefault();
            const frm = $(this).closest('form');

            if(filterRequest !== null) {
                filterRequest.abort();
            }
            if($(this).attr('name') !== 'page') {
                $('[name="page"]').val(1);
            }

            filterRequest = $.ajax({
                method: frm.attr('method'),
                url: frm.attr('action'),
                data: frm.serialize(),
                dataType: "json",
                success: function (payload) {
                    fn_ajaxHandler(payload,function() {
                        const products = selector.find('[data-ref="products"]')
                        products.anchor_anim(300)
                        sr.sync();
                    });
                }
            });
        });

        window.lui[name] = comp;
        window.lui[name].fns = fns;
    }
})("comp_site_products_used");


