(function($) {
    doc.on("change", ".part_ui_file input", function(){
        let elm = $(this);

        if (!elm.filter("[multiple]").length) {
            elm.closest(".part_ui_file").find("span").text($(this).val().replace(/.*(\/|\\)/, ''));
        } else {
            let files = this.files;
            let files_html = elm.closest("[data-files-wrapper]").find("[data-files-items]");

            files_html.html("");

            elm.closest("[data-files-wrapper]").find("[data-remove-files]").show();

            Object.keys(files).forEach(function(key) {
                let size = (files[key].size / 1048576).toFixed(3);
                let name = files[key].name;

                let template = `
                <div class="elm_item">
                    <div class="col">
                        <div class="elm_item_name"><span>${name}</span></div>
                    </div>
                    <div class="col">
                        <div class="elm_item_size"><span>${size} MB</span></div>
                    </div>
                </div>`;

                files_html.append(template);
            });
        }
    });

    doc.on("click", "[data-remove-files]", function(){
        let elm = $(this);

        elm.hide();
        elm.closest("[data-files-wrapper]").find("[data-files-items]").html("");
        elm.closest("[data-files-wrapper]").find(".part_ui_input input").val("");
    });
})(jQuery);