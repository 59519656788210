(function($){
    const fn = $(".part_dialog_exit_banner");

    if (fn.length) {
        let fn_html = fn.clone();
        let fn_delay = fn.data("banner-delay");


        bodyLoaded(function(){
            if (!('ontouchstart' in document.documentElement)) {
                setTimeout(function(){
                    exitDetect(function(){
                        nl_lib_dialog.open(fn_html);
                    });
                },fn_delay);
            } else if (win.width() > 768) {
                setTimeout(function () {
                    nl_lib_dialog.open(fn_html);
                }, fn_delay);
            }
        });

        $(document).on('click', '[data-exit-banner-close]', function() {
            $.ajax({
                type: 'POST',
                data: {'do': 'exitBannerClosed'},
                dataType: 'json'
            });
        });
    }
})(jQuery);