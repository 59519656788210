(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {
        let banner = selector.find(".wrp_comp_banner");

        if(banner.length) {
            cssLoaded(function () {
                let video = banner.find("video").get(0);
                setTimeout(function () {
                    banner.addClass("mod--active");
                    video.play();
                },1000);

                $(video).on("ended",function () {
                   setTimeout(function () {
                       video.play()
                   },10000);
                });

                banner.on("click","[data-click]",function () {
                    video.pause();
                    banner.removeClass("mod--active");
                    setTimeout(function () {
                        banner.remove();
                    },1000);
                })
            });
        }

        window.lui[name] = comp;
    }
})("comp_base_visual");


