(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {
        let fns = {};

        (function fn_flickity() {
            $.lib_flickity(function(){

                let slider = selector.find("[data-slider]");
                let align = selector.find("[data-slider-align]").data("slider-align");

                if (slider.length) {
                    slider.flickity({
                        groupCells: true,
                        cellAlign: align,
                        contain: true,
                        pageDots: false,
                        wrapAround: false,
                        autoPlay: selector.find('[data-slider]').data("slider"),
                        prevNextButtons: false,
                        pauseAutoPlayOnHover: true
                    });

                    slider.addClass("flickity-flex");

                    selector.find("[data-slider-nav]").on("click", function() {
                        let elm = $(this);
                        let elm_dir = elm.data("slider-nav");

                        if (elm_dir === "prev") {
                            slider.flickity('previous');
                        } else {
                            slider.flickity('next');
                        }
                    });

                    function fn_arrows() {
                        let items_lenght =  slider.find(".flickity-slider").children().length;
                        let items_width = slider.find(".flickity-slider").children().outerWidth();
                        let width = slider.find(".flickity-slider").width();

                        if (width >= (items_lenght * items_width)) {
                            selector.find("[data-slider-nav]").hide();
                        } else {
                            selector.find("[data-slider-nav]").show();
                        }
                    }

                    win.on("resize", ()=>{
                        fn_arrows();
                        slider.removeClass("flickity-flex");
                        slider.flickity('resize');
                        slider.addClass("flickity-flex");
                    });
                    fn_arrows();
                }
            });

            fns.fn_flickity = fn_flickity;
        })();

        window.lui[name] = comp;
        window.lui[name].fns = fns;
    }
})("comp_base_news");
