jQuery.lib_flickity = function(callback) {
    if (!html.hasClass("ie")) {
        if (callback) {
            $.importScript(cdnjs.flickity, function() {
                cssLoaded(function () {
                    callback();
                });
            });
        }
    }
};