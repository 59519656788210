(function($) {
    const fn = $(".comp_base_location");
    if(fn.length) {
        sr.reveal("[data-map-reveal]", {
            duration: 1000,
            easing: 'ease',
            scale: null,
            distance: 0,
            delay: 300,
            viewFactor : 0.2,
            reset: false,
            mobile: true,
            container: document.getElementsByTagName("section"),
            beforeReveal: function (domEl) {
                $.importScript(cdnjs.googlemap).done(function () {
                    fn.find("[data-contact-map]").each(function (i) {
                        console.log(i);
                        let options;
                        if (i === 0) {
                            options = {
                                zoomControlOptions: {
                                    position: google.maps.ControlPosition.LEFT_BOTTOM
                                },
                                streetViewControlOptions: {
                                    position: google.maps.ControlPosition.LEFT_BOTTOM
                                },
                                fullscreenControlOptions: {
                                    position: google.maps.ControlPosition.LEFT_TOP
                                },
                            }
                        }
                        $(this).nl_google_map('init', '', options);
                        $(this).nl_google_map('markers_add');
                    });
                });
            },
            afterReveal: function (domEl) {
                $(domEl).removeAttr("style");
            }
        },0);
    }
})(jQuery);