function fn_lib_dialog() {
    const dialog = $("[data-dialog]");

    function fn_lib_dialog_show(data,callback) {
        doc.find(".lib--dialog .part_dialog").remove();
        if (!doc.find(".lib--dialog").length) {
            body.append('<div class="lib--dialog"></div>');
        }

        doc.find(".lib--dialog").append(data).fadeIn(300);

        if (doc.find(".lib--dialog > .part_dialog").outerHeight(true) > win.height()) {
            let offset = window.innerWidth - document.body.clientWidth;
            body.css("padding-right", offset).addClass("mod--no-scroll");
            $("#layout_header, .layout_contact").css("right", offset).addClass("mod--no-scroll");
        }

        if (callback) {
            callback();
        }

        doc.find(".lib--dialog").on("click", function(e) {
            if ($(e.target).hasClass("lib--dialog")) {
                fn_lib_dialog_hide();
            }
        });
    }

    function fn_lib_dialog_hide() {
        dialog.removeClass("mod--active");
        doc.find(".lib--dialog").fadeOut(300);

        setTimeout(function () {
            body.removeClass("mod--no-scroll").css("padding-right","");
            $("#layout_header, .layout_contact").css("right","");

            doc.find(".lib--dialog").remove();
        }, 300);
    }

    function fn_lib_dialog_init(callback) {
        doc.on('click', '[data-dialog]', function (e) {
            let el = $(this);
            if($(e.target).is("a") && $(e.target).not("[data-dialog]")) {
                return;
            }
            e.preventDefault();
            el.addClass("mod--active");

            $.ajax({
                dataType: "json",
                data: {ajax: +new Date},
                url: el.data('dialog')
            }).done(function(data) {
                fn_lib_dialog_show(data.dialog, callback);
            });
        });

        if (body.filter("[data-dialog-open]").length) {
            $.ajax({
                dataType: "json",
                data: {ajax: +new Date},
                url: body.filter("[data-dialog-open]").data('dialog-open')
            }).done(function(data) {
                bodyLoaded(function(){
                    fn_lib_dialog_show(data.dialog, callback);
                });
            });
        }

        doc.on("click", ".elm_dialog_background, [data-dialog-close]", function (e) {
            e.preventDefault();
            fn_lib_dialog_hide();
        });
    }

    return {
        open: fn_lib_dialog_show,
        close: fn_lib_dialog_hide,
        init: fn_lib_dialog_init
    };
}
const nl_lib_dialog = fn_lib_dialog();