(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {
        let fns = {};

        (function fn_range_slider() {
            let range = selector.find(".part_ui_range");
            if (range.length) {
                range.each(function(){
                    let elm = $(this);
                    $.importScript(cdnjs.autoNumeric, function(){
                        $.importScript(cdnjs.noui, function(){
                            elm.lib_noui({
                                elm_min: elm.find(`[data-range-input="min"]`),
                                elm_max: elm.find(`[data-range-input="max"]`),
                                elm_min_hidden: elm.find(`[data-range-input="min"]`).next(),
                                elm_max_hidden: elm.find(`[data-range-input="max"]`).next(),
                                unit: elm.data("unit")
                            });
                        });
                    });
                });
            }

            fns.fn_flickity = fn_range_slider;
        })();

        selector.on("click", "[data-toggle-filter]", function(){
            let elm = $(this);
            let elm_text = elm.data("toggle-filter");

            if (!elm.hasClass("mod--color-invert")) {
                elm.addClass("mod--color-invert");
                elm.closest(".part_filter").find(".elm_head_content").slideDown(300);
                elm.find("span").text(elm_text[1]);
            } else {
                elm.removeClass("mod--color-invert");
                elm.closest(".part_filter").find(".elm_head_content").slideUp(300);
                elm.find("span").text(elm_text[0]);
            }
        });

        window.lui[name] = comp;
        window.lui[name].fns = fns;
        
        $('[data-filter]').on('change', function(){
            $(this).closest('form').submit();
        });
    }
})("part_filter");


