let cdnjs = {
    "touchswipe": "https://cdn.jsdelivr.net/npm/jquery-touchswipe@1.6.18/jquery.touchSwipe.min.js",
    "headroom": "https://cdn.jsdelivr.net/npm/headroom.js@0.9.4/dist/headroom.min.js",
    "googlemap": "https://maps.googleapis.com/maps/api/js?key=AIzaSyAi248kAdf-e81qaySninPBUAJe6L0Dzz0",
    "flickity": "https://cdn.jsdelivr.net/npm/flickity@2.1.1/dist/flickity.pkgd.min.js",
    "noui": "https://cdn.jsdelivr.net/npm/nouislider@10.1.0/distribute/nouislider.min.js",
    "autoNumeric": "https://cdn.jsdelivr.net/npm/autonumeric@4.2.15/dist/autoNumeric.min.js",
    "lightgallery": "https://cdn.jsdelivr.net/npm/lightgallery@1.6.11/dist/js/lightgallery.min.js",
    "lightgallery_css": "https://cdn.jsdelivr.net/npm/lightgallery@1.6.11/dist/css/lightgallery.min.css",
    "tomselect": "https://cdn.jsdelivr.net/npm/tom-select@2.2.2/dist/js/tom-select.complete.min.js",
    "tomselect_css": "https://cdn.jsdelivr.net/npm/tom-select@2.2.2/dist/css/tom-select.min.css"
};
