(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {

        selector.on("click", "[data-toggle-faq]", function(){
            let elm = $(this);

            if (!elm.hasClass("mod--active")) {
                elm.addClass("mod--active");
                elm.closest(".elm_item").find(".elm_item_body").slideDown(300);
                elm.find(".icon").removeClass("icon--plus").addClass("icon--close");
            } else {
                elm.removeClass("mod--active");
                elm.closest(".elm_item").find(".elm_item_body").slideUp(300);
                elm.find(".icon").addClass("icon--plus").removeClass("icon--close");
            }
        });

        window.lui[name] = comp;
    }
})("comp_base_faq");


