(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {
        $('[data-part-add]').on('click', function () {
            var last = $('[data-part]').last();
            var newRow = last.clone();
            var rowNumber = newRow.data('part') + 1;
            newRow.attr('data-part', rowNumber);
            newRow.find('input').val('');

            last.after(newRow);

            countPrices();
        });

        $(document).on('click', '[data-part-remove]', function () {
            if ($('[data-part]').length > 1) {
                $(this).closest('[data-part]').remove();
            }
            countPrices();
        });

        $(document).on('input', '[data-part-quantity], [data-part-price]', function () {
            countPrices();
        });

        var countPrices = function () {
            var totalPrice = 0;

            $('[data-part]').each(function () {
                var row = $(this);
                var price = parseFloat(row.find('[data-part-price]').val()) || 0;
                var quantity = parseInt(row.find('[data-part-quantity]').val()) || 0;
                var total = Math.round(price * quantity * 100) / 100;

                $(this).find('[data-part-total]').val(total);
                totalPrice += total;
            });

            $('[data-total-price]').text(totalPrice);
        };
    }
})("comp_site_warranty");