(function comp(name) {
    const selector = $(`.${name}`);

    if (window.location.hash) {
        selector.find('[data-id=' + window.location.hash.substr(1) + ']').click();
    }

    doc.on('click', '[data-change-hash]', function () {
        window.location.hash = '#' + $(this).data('id');
    });
})("comp_site_contact_info");