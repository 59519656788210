var reInitGCaptcha;

(function($) {
    reInitGCaptcha = function() {
        $.getScript("https://www.google.com/recaptcha/api.js?render=6Le-2pYUAAAAAO8pT165-R7_jDV07l55TkUM0vhV").done(function () {
            grecaptcha.ready(function () {
                grecaptcha.execute('6Le-2pYUAAAAAO8pT165-R7_jDV07l55TkUM0vhV', {action: 'form'})
                    .then(function (token) {
                        $('#g-token').val(token);
                    });
            });
        });
    };

    if ($('form[data-recaptcha]').length) {
        reInitGCaptcha();
        setInterval(function(){reInitGCaptcha();}, 150000);
    }

    fn_nl_lib_anchor();

    $(".lib--parallax").find("img:not(.lazyload)").parent().parallax();

    doc.on('lazybeforeunveil', '.lazyload', function(e){
        if ($(e.target).parent().hasClass("lib--parallax")) {
            $(e.target).parent().parallax();
        }
    });

    nl_lib_dialog.init(function(){
        Object.keys(window.lui.lib).forEach(function(key) {
            let dialog_selector = doc.find(".lib--dialog");
            let s = window.lui.lib[key].selector;

            if (dialog_selector.find(s[0]).length) {
                window.lui.lib[key](dialog_selector.find(s[0]),s[1])
            }
        });
    });

    $.libInit("[data-switch]", "lib_switch", function(selector) {
        $(selector).nl_lib_switch();
    });

    $.libInit(".part_ui_btn, .part_ui_btn_square", "lib_ripple", function(selector) {
        $(selector).nl_lib_ripple();
    });

    $.libInit("[data-lib-gallery]", "lib_gallery", function(selector) {
        let gallery = $(selector);

        if (gallery.length) {
            $.importStyle(cdnjs.lightgallery_css);
            $.importScript(cdnjs.lightgallery, function(){
                gallery.lightGallery({
                    thumbnail: true,
                    selector: ".lib--gallery-item",
                    exThumbImage: 'data-exthumbimage',
                    fullScreen: false,
                    zoom: true,
                    actualSize: false,
                    hash: true,
                    download: true,
                    autoplay: false,
                    autoplayControls: true
                });
            });
        }
    });

    $.libInit(".part_form", "lib_form", function (selector) {
        window.dataLayer = window.dataLayer || [];

        $(selector).each(function () {
            let element = $(this)[0];

            if(element.closest(".lib--dialog") !== null) {
                window.dataLayer.push({
                    'event': 'formOpen'
                });
            }

            if (typeof HTMLFormElement.prototype.reportValidity !== "undefined") {
                element.setAttribute("novalidate", "");

                $(element).off("submit");
                $(element).on("submit",function (event) {
                    if (element.reportValidity() === false) {
                        event.preventDefault();
                        event.stopPropagation();

                        [...element.querySelectorAll(".part_ui_input, .part_ui_select")].map((element) => {
                            let input = element.querySelectorAll("input, textarea, select");

                            [...input].map((input) => {
                                if (!input.checkValidity()) {
                                    let validityStates = input.validity;

                                    for (const key in validityStates) {
                                        if (validityStates[key] === true) {
                                            window.dataLayer.push({
                                                'event': 'formValidationFailed',
                                                'errorMessages': `${input.name}-${key}`
                                            });
                                        }
                                    }
                                }
                            });
                        });
                    }
                });
            }
        });
    });

    $.libInit(".part_ui_tomselect", "lib_tomselect", function (selector) {
        $.importStyle(cdnjs.tomselect_css);
        $.importScript(cdnjs.tomselect, function(){
            $(selector).each(function () {
                const element = $(this)

                if(element.hasClass('is--tomselect')) {
                    return
                }

                const select = element.find('select')[0]
                const plugins = []

                if(select.multiple) {
                    plugins.push('checkbox_options')
                    plugins.push('remove_button')
                }

                const tsInstance = new TomSelect(select, {
                    create: false,
                    plugins: plugins,
                    render: {
                        option: function (data, escape) {
                            if(typeof data.icon !== 'undefined') {
                                return `<div><span class="icon ${escape(data.icon)}"></span>${escape(data.text)}</div>'`;
                            } else {
                                return '<div>' + escape(data.text) + '</div>';
                            }
                        }
                    },
                    onInitialize: function () {
                        element.addClass('is--tomselect')
                    }
                })

                tsInstance.on('initialize', function () {
                    element.addClass('is--initialized')
                })

                tsInstance.on('dropdown_open', function (dropdown) {
                    element[0].style.zIndex = 10
                    element.addClass('mod--focus')
                })

                tsInstance.on('dropdown_close', function (dropdown) {
                    element[0].style.zIndex = null
                    element.removeClass('mod--focus')
                })
            })
        })
    });

    doc.on('contextmenu',".lg-img-wrap",function (e) {
        e.preventDefault();
        e.stopPropagation();
    });

    // AJAX select
    $(document).on('change', '[data-select-ajax]', function (e) {
        e.preventDefault();
        location.href = $(this).find('option:selected').data('url');
    });

   doc.on('submit', 'form[data-component-ajax]', function (e) {
        e.preventDefault();
        let frm = $(this),
            formData = new FormData($(this)[0]);

        $.ajax({
            method: frm.attr('method'),
            url: frm.attr('action'),
            data: formData,
            processData: false,
            contentType: false,
            dataType: "json",
            success: function (payload) {
                fn_ajaxHandler(payload, function () {
                    if ($('form[data-recaptcha]').length) {
                        reInitGCaptcha();
                    }
                });
            }
        });
    });

    doc.on('click','a[data-component-ajax]',function(e) {
        e.preventDefault();
        let url = $(this).attr('data-href');
        if(!url || !url.length) {
            url = $(this).attr('href');
        }

        if(!url || !url.length) {
            console.log('no href attribute for ajax component link');
            return;
        }

        $.ajax({
            url: url,
            method: 'post',
            dataType: 'json'
        }).done(function (payload) {
            fn_ajaxHandler(payload,function() {
                sr.sync();
            });
        });
    });
    doc.on('click', '[data-page]', function (e) {
        e.preventDefault();
        $('[name="page"]').val($(this).data('page')).change();
    });
    if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
        html.addClass("ie11");
    }

    if ($(".part_cookie_consent").length || $(".part_form_cookies").length) {
        $.importScript(cdnjs.cookieconsent);
    }

    // AJAX FORM
    $(document).on('submit', 'form.ajax', function (e) {
        e.preventDefault();
        var $frm = $(this);
        var formData = new FormData($(this)[0]);
        $.ajax({
            method: $frm.attr('method'),
            url: $frm.attr('action'),
            data: formData,
            processData: false,
            contentType: false
        }).done(function (payload) {
            fn_ajaxHandler(payload);
            if($('#snippet-newsletter').length) {
                $('#snippet-newsletter').antiSpam();
            }
            if($('#snippet-contactform').length) {
                $('#snippet-contactform').antiSpam();
            }
            if($('#snippet-careerform').length) {
                $('#snippet-careerform').antiSpam();
            }
        });
    });

    if($('#snippet-newsletter').length) {
        $('#snippet-newsletter').antiSpam();
    }

    if($('#snippet-contactform').length) {
        $('#snippet-contactform').antiSpam();
    }

    if($('#snippet-careerform').length) {
        $('#snippet-careerform').antiSpam();
    }

    doc.on("click", "[data-load-more]", function(){
        let elm = $(this);
        let elm_wrapper = $(this).closest(".comp_site_articles").find("[data-wrapper-grid]");
        elm.addClass("mod--loading");
        $.ajax({
            url: elm.data("load-more"),
            data: {ajax: +new Date},
            dataType: "json",
            success: function (data) {
                elm.removeClass("mod--loading");
                var snippetId = elm_wrapper.attr('id');
                var snippetData = $(data['snippets'][snippetId]).find('#'+snippetId).html();
                elm_wrapper.append(snippetData);
                var snippetDataButton = $(data['snippets'][snippetId]).find('#'+snippetId+'_button');
                $('#'+snippetId+'_button').replaceWith(snippetDataButton);
                if(data.payload.last) {
                    elm.hide();
                } else {
                    elm.show();
                }
                sr.sync();
            }
        });
    });


    doc.on('change', '[data-change-person]', function() {
        var type = $(this).val();
        var form = $(this).closest('form');

        form.find('[data-' + type + ']').each(function(){
            var m = $(this).data(type);

            if(m == 'required') {
                $(this).show().val;
                $(this).find('input').prop('required', true);
            } else if(m == 'optional') {
                $(this).show();
                $(this).find('input').prop('required', false);
            } else if(m == 'hidden') {
                $(this).hide();
                $(this).find('input').prop('required', false).val('');
            }

            if($(this).find('input').is('[data-default]')) {
                $(this).find('input').val($(this).find('input').attr('data-default'));
            }


        });
    });

    doc.on('click', '[data-trigger-click]', function(){
        var elm = $(this);
        $(elm.data('trigger-click')).click();
    });

    doc.on('change', '[name="ico"]', function(){
        var elm = $(this);

        $.ajax({
            method: 'POST',
            data: {do: 'getVatData', ico: elm.val()},
            dataType: 'json',
        }).done(function(response){
            if(response) {
                $('[name="company"]').val(response.company);
                $('[name="dic"]').val(response.tin);
            }
        })
    });

    $(document).on('click', '[data-dialog-close-cookie]', function() {
        console.log('xxx');
        $.ajax({
            type: 'GET',
            data: {'dox': 'exitInfoPopup'},
            dataType: 'json'
        });
    });


    var hashtag = window.location.hash;
    if(hashtag == '#form') {
        $("[data-open]")[0].click();
    }

})(jQuery);
