(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {
        let fns = {};

        (function fn_flickity() {
            $.lib_flickity(function(){

                let slider = selector.find("[data-slider]");

                slider.each(function(){
                    let elm_slider = $(this);

                    let items_lenght =  elm_slider.children().length;
                    let items_width = elm_slider.children().outerWidth();
                    let width = elm_slider.width();
                    let align;

                    if (width >= (items_lenght * items_width)) {
                        align = 'center'
                    } else {
                        align = 'left'
                    }

                    elm_slider.flickity({
                        groupCells: '25%',
                        cellAlign: align,
                        contain: true,
                        pageDots: false,
                        wrapAround: false,
                        autoPlay: elm_slider.data("slider"),
                        prevNextButtons: false,
                        pauseAutoPlayOnHover: true,
                        draggable: false
                    });

                    elm_slider.closest(".wrp_comp_body").find("[data-slider-nav]").on("click", function() {
                        let elm = $(this);
                        let elm_dir = elm.data("slider-nav");

                        if (elm_dir === "prev") {
                            elm_slider.flickity('previous');
                        } else {
                            elm_slider.flickity('next');
                        }
                    });

                    function fn_arrows() {
                        let items_lenght =  elm_slider.find(".flickity-slider").children().length;
                        let items_width = elm_slider.find(".flickity-slider").children().outerWidth();
                        let width = elm_slider.find(".flickity-slider").width();

                        if (width >= (items_lenght * items_width)) {
                            elm_slider.closest(".wrp_comp_body").find("[data-slider-nav]").hide();
                        } else {
                            elm_slider.closest(".wrp_comp_body").find("[data-slider-nav]").show();
                        }
                    }

                    win.on("resize", fn_arrows);
                    fn_arrows();
                });
            });

            fns.fn_flickity = fn_flickity;
        })();

        window.lui[name] = comp;
        window.lui[name].fns = fns;
    }
})("comp_base_brands");